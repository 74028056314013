import React from 'react';

const Check = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 2.66668C6.58519 2.66668 5.22863 3.22858 4.22844 4.22877C3.22824 5.22897 2.66634 6.58552 2.66634 8.00001C2.66634 9.4145 3.22824 10.7711 4.22844 11.7712C5.22863 12.7714 6.58519 13.3333 7.99967 13.3333C9.41416 13.3333 10.7707 12.7714 11.7709 11.7712C12.7711 10.7711 13.333 9.4145 13.333 8.00001C13.333 6.58552 12.7711 5.22897 11.7709 4.22877C10.7707 3.22858 9.41416 2.66668 7.99967 2.66668ZM1.33301 8.00001C1.33301 4.31801 4.31767 1.33334 7.99967 1.33334C11.6817 1.33334 14.6663 4.31801 14.6663 8.00001C14.6663 11.682 11.6817 14.6667 7.99967 14.6667C4.31767 14.6667 1.33301 11.682 1.33301 8.00001ZM11.109 5.83534C11.241 5.9528 11.321 6.11785 11.3313 6.29423C11.3417 6.47061 11.2817 6.64389 11.1643 6.77601L7.60901 10.776C7.54646 10.8464 7.46971 10.9027 7.38382 10.9413C7.29793 10.9799 7.20483 10.9998 7.11067 10.9998C7.01651 10.9998 6.92342 10.9799 6.83753 10.9413C6.75164 10.9027 6.67489 10.8464 6.61234 10.776L4.83501 8.77601C4.72442 8.64298 4.66987 8.47218 4.68289 8.29968C4.6959 8.12718 4.77546 7.96649 4.90476 7.85156C5.03405 7.73663 5.20296 7.67646 5.37579 7.68376C5.54863 7.69106 5.71186 7.76526 5.83101 7.89068L7.11101 9.33001L10.1683 5.89001C10.2859 5.75811 10.451 5.67827 10.6274 5.66802C10.8037 5.65777 10.977 5.71795 11.109 5.83534Z"
        fill="white"
      />
    </svg>
  );
};

export default Check;
